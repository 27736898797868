import React from "react"
import "bootstrap/dist/css/bootstrap.min.css"
import Navbar from "react-bootstrap/Navbar"
import { Nav, NavDropdown } from "react-bootstrap"
import { getUser, isLoggedIn, logout } from "../services/auth"
import { navigate } from "gatsby"

class NavBar extends React.Component {
  handleSubmit(e) {
    e.preventDefault()
    logout(() => navigate("/app/login"))
    console.log("You clicked submit")
  }
  render() {
    let greetingMessage = ""
    if (isLoggedIn()) {
      greetingMessage = `欢迎你， ${getUser().username}`
    } else {
      greetingMessage = "你还未登录"
    }
    return (
      <div>
        <Navbar bg="light" expand="lg">
          <Navbar.Brand href="#home">Survivor家园系统</Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ml-auto">
              <Nav.Link href="/"> 主页</Nav.Link>
              <Nav.Link href="/" onClick={this.handleSubmit}>
                {" "}
                退出
              </Nav.Link>
              <Nav.Link href="/history"> 部落</Nav.Link>
              <Nav.Link href="/items"> 物品</Nav.Link>
              <NavDropdown title="功能" id="basic-nav-dropdown">
                <NavDropdown.Item href="/collect"> 采集/打鱼</NavDropdown.Item>
                <NavDropdown.Item href="/makefire">生火</NavDropdown.Item>
                <NavDropdown.Item href="/find"> 探索</NavDropdown.Item>
              </NavDropdown>
              <Nav.Link href="/help"> 帮助</Nav.Link>
              <Nav.Link style={{ color: "black" }} className="ml-5">
                {" "}
                {greetingMessage}{" "}
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      </div>
    )
  }
}

export default NavBar
